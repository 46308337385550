<template>
  <v-container fluid>

    <template>
      <div style="background-color: rgb(118 126 137);">
        <v-breadcrumbs :items="breadcrumbs">
            <template v-slot:divider>
                <v-icon>mdi-forward</v-icon>
            </template>
        </v-breadcrumbs>
      </div>
    </template>
  
    <v-row class="mt-2">
      <v-col cols="12">
        <v-card dark outlined>
            
            <v-card-title class="secondary py-1 text-subtitle-1">
                <v-icon left dense> mdi-flag </v-icon>
                Stallholders Configuration
                <v-spacer/>
            </v-card-title>

            <v-card-text>
                    <v-form ref="form" v-model="valid" lazy-validation>
                        <!-- <v-row>
                            <v-col cols="12"> -->
                              <v-text-field
                                v-model="editedItem.EventName"
                                label="Event Name"
                                :rules="eventNameRules"
                                required
                              ></v-text-field>
                            <!-- </v-col> -->

                            <!-- <v-col cols="12"> -->
                              <v-text-field
                                v-model="editedItem.Username"
                                label="Username"
                                required
                              ></v-text-field>
                            <!-- </v-col> -->

                            <!-- <v-col cols="12"> -->
                              <v-text-field
                                v-model="editedItem.Password"
                                label="Password"
                                type="number"
                                required
                              ></v-text-field>
                            <!-- </v-col> -->
                            <v-row class="mb-2">
                              <v-col>
                                <label>Payment Profile</label>
                                <v-row class="ml-1">
                                    <v-checkbox
                                    v-model="editedItem.PaymentProfile"
                                    label="Cash"
                                    value="Cash"
                                    class="mr-5"
                                    ></v-checkbox>
                                    <v-checkbox
                                    v-model="editedItem.PaymentProfile"
                                    label="Card"
                                    value="Card"
                                    class="mr-5"
                                    ></v-checkbox>
                                    <v-checkbox
                                    v-model="editedItem.PaymentProfile"
                                    label="Voucher"
                                    value="Voucher"
                                    ></v-checkbox>
                                </v-row>
                              </v-col>
                            </v-row>
                         
                            <!-- <row>
                              <v-col> -->
                                <label>Printing Profile</label>
                                <v-row class="ml-1">
                                    <div v-for="item in PrintingList" :key="item.id">
                                    <v-checkbox
                                        v-model="editedItem.PrintingProfile"
                                        :label="item.name"
                                        :value="item.name"
                                        class="mr-5"
                                        :rules="eventPrintRules"
                                        required
                                    ></v-checkbox>
                                    </div>
                                </v-row>
                              <!-- </v-col>
                            </row> -->

                            <!-- <v-col cols="12">  -->
                              <v-combobox
                                v-model="editedItem.ShowInPOS"
                                :items="POSTypes"
                                label="Show in POS"
                                :rules="[v => !!v || 'Show in POS is required']"
                                required
                              ></v-combobox>
                            <!-- </v-col> -->
                             
                            <!-- <v-col cols="12"> -->
                              <v-text-field
                                v-model="editedItem.Headcount"
                                label="Head Count"
                                type="number"
                                min="0"
                                :rules="headcountRules"
                                required
                              ></v-text-field>
                            <!-- </v-col> -->
                            
                            <!-- <v-col cols="12"> -->
                              <v-combobox
                                v-model="editedItem.Year"
                                :items="Years"
                                label="Year"
                                :rules="[v => !!v || 'Year is required']"
                                required
                              ></v-combobox>
                            <!-- </v-col> -->
                            <!-- </v-col> -->

                            <!-- <v-col cols="12"> -->
                              <v-combobox
                                v-model="editedItem.Action"
                                :items="ActionList"
                                label="Action"
                                :rules="[v => !!v || 'Action is required']"
                                required
                              ></v-combobox>
                            <!-- </v-col> -->

                        <!-- </v-row> -->
                    </v-form>
            </v-card-text>

            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                @click="close"
            >
                Cancel
            </v-btn>
            <v-btn
                color="blue darken-1"
                text
                @click="save"
            >
                Save
            </v-btn>
            </v-card-actions>

        </v-card>
      </v-col>
    </v-row>

  </v-container>
</template>
<script>
    export default {
      data: () => ({
        breadcrumbs: [
            {
            text: 'Stallholders',
            disabled: false,
            href: 'StallholdersStallsStaffTerminals',
            color: "green"
            },
            {
            text: 'Stallholders Configuration',
            disabled: true,
            href: '',
            color: "blue"
            },
        ],
        editedItem: {
            EventName: '',
            Username: '',
            Password: '',
            PaymentProfile: [],
            PrintingProfile: '',
            ShowInPOS: '',
            Year: '',
            Headcount: '',
            Action: '',
            PaymentProfileInherit: true,
            PrintingProfileInherit: true,
            CardSurchargePercentageInherit: true,
            StandardDrinkLimitInherit: true,
            StandardDrinkLimitEnforceInherit: true
        },
        PrintingList: [
            {
            id: 1,
            name: "Print"
            },
            {
            id: 2,
            name: "Prompt"
            },
            {
            id: 3,
            name: "Bypass"
            }
        ],

        POSTypes: ['YES', 'NO'],
        Years: ['2023', '2022', '2021', '2020', '2019'],
        ActionList: ['create', 'update ', 'delete'],
    
        eventNameRules: [
            v => !!v || 'Event Name is required',
        ],
        eventPrintRules: [
            v => !!v || 'Printing Profile is required',
        ],
        eventPercentageRules: [
            v => (v && v > 0) || 'Card Surcharge Percentage must be greater than 0'
        ],

        headcountRules: [
            v => (v && v > 0) || 'Head Count must be greater than 0'
        ]
      })
    }
</script>